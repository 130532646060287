@layer components {

    .gabungin-notification {

        @apply bg-blue-400 m-1 mt-0 py-3 px-5 rounded-lg text-xs text-white;

        .title {
            @apply font-bold text-white text-sm;
        }

        .close {
            @apply text-white;
        }

        // types (green, amber, red)
        &.success {
            @apply bg-green-400;
        }

        &.warning {
            @apply bg-orange-400;
        }

        &.error {
            @apply bg-red-400;
        }
    }
}
