@layer components {

    .laravel-vue-datatable {
        @apply items-center mb-0 align-top text-slate-500 w-full;
    }

    .laravel-vue-datatable-thead {

    }

    .laravel-vue-datatable-th {
        @apply px-6 py-3 font-bold text-left uppercase align-middle bg-transparent border-b border-gray-200 shadow-none tracking-none whitespace-nowrap text-slate-400;
    }

    .laravel-vue-datatable-td {
        @apply bg-150 py-3;
    }

}
