@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./fa6/scss/fontawesome.scss";
@import "./fa6/scss/regular.scss";
@import "./fa6/scss/solid.scss";
@import "./fa6/scss/brands.scss";
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '~vue2-timepicker/dist/VueTimepicker.css';
@import './argon/kanban.css';
@import 'vue-kanban';
@import "vuejs-dialog";
@import "./vue-notifications";
@import "./daisyui/daisy-integration";
@import "./animations/animations";
@import "./vue-timepicker-custom";

.landing-page {
    font-family: system-ui;

    &.bg-gradient-auth {
        height: 100%;
        background: rgb(143, 224, 255);
        background: linear-gradient(38deg, rgba(143, 224, 255, 1) 0%, rgba(37, 154, 203, 1) 18%, rgba(37, 154, 203, 1) 54%, rgba(102, 211, 236, 1) 85%, rgba(37, 154, 203, 1) 100%);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        height: 100vh;
    }

    .stressed-mark::after {
        content: '';
        background: url("/images/landing_page/features/red.svg") no-repeat;
        background-position: center center;
        background-size: cover;
        height: 20.18px;
        width: 155px;
        position: absolute;
        left: 240px;
        margin-top: 34px;
        z-index: -1;
    }

    .bg-gradient-btn {
        background: linear-gradient(134deg, #1343A1 16.37%, #98BBFF 94.77%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .bg-gradient-btn:hover {
        background-clip: unset;
        -webkit-background-clip: unset;
        -webkit-text-fill-color: unset;
        background: #106D9C;
        color: white;
    }
    p {
        margin-bottom: 0;
    }
}
