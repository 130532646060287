@layer components {

    .sidenav-anchor-collapse {
        @apply ease-in-out py-2.7 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium shadow-none transition-colors ml-5.4;

        &:after {
            @apply ease-in-out ml-auto inline-block font-bold antialiased transition-all duration-200;
            font-family: 'Font Awesome 6 Free';
            content: '\f0d7';
        }

    }

    .sidenav-anchor-open {
        @apply ease-in-out py-2.7 pl-4 leading-default text-sm relative my-0 mr-2 flex items-center whitespace-nowrap bg-transparent pr-4 font-medium shadow-none transition-colors ml-5.4;

        &:after {
            @apply ease-in-out ml-auto inline-block font-bold antialiased transition-all duration-200 rotate-180;
            font-family: 'Font Awesome 6 Free';
            content: '\f0d7';
        }
    }

}
