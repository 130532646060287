@layer components {

    aside {

        ul {

            li {

                a {
                    @apply text-slate-700/80;
                }

                i {
                    @apply text-slate-700/80;
                }

            }

        }

    }

}
