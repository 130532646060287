@layer components {
    // badge
    .badge {
        @apply py-1 px-2 text-xxs border-0 rounded-1 inline-block whitespace-nowrap text-center align-baseline font-normal uppercase leading-none;
    }
    .badge-sm {
        @apply py-0 text-xxs;
    }
    .badge-primary {
        @apply text-blue-700 bg-blue-200;
    }
    .badge-secondary {
        @apply text-slate-700 bg-slate-200;
    }
    .badge-info {
        @apply text-cyan-700 bg-cyan-200;
    }
    .badge-accent {
        @apply text-teal-700 bg-teal-200;
    }
    .badge-success {
        @apply text-emerald-700 bg-emerald-200;
    }
    .badge-error {
        @apply text-red-700 bg-red-200;
    }
    .badge-warning {
        @apply text-orange-700 bg-orange-200;
    }
    .badge-ghost {
        @apply text-slate-700 bg-gray-200;
    }
}
