$ease-out: all .3s cubic-bezier(0.23, 1, 0.32, 1);

@layer components {

    .drag-container {
        @apply relative w-full py-5;
    }

    .drag-container:after {
        clear: both;
        display: block;
        content: "";
    }

    .drag-list {

        @apply flex flex-nowrap gap-4;

        @media (max-width: 690px) {
            display: block;
        }
    }

    .drag-column {

        width: 250px;
        @apply flex-none rounded-lg bg-[#e9ecef] pb-[80px];

        @media (max-width: 690px) {
            margin-bottom: 30px;
        }

        h2 {
            font-size: 0.8rem;
            margin: 0;
            text-transform: uppercase;
            font-weight: 600;
        }

        .drag-item {
            transition: $ease-out;
            @apply flex-none bg-white rounded text-slate-500 p-3 m-2;

            &:hover {
                @apply cursor-grab;
            }

            &:active {
                //transform: scale(1.2);
                @apply cursor-grabbing;
            }
        }

    }


    .drag-inner-list {
        min-height: 50px;
        color: white;

        > span {
            @apply w-full inline-block h-screen;
        }
    }


}

ul.drag-list, ul.drag-inner-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.drag-column-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}


.drag-header-more {
    cursor: pointer;
}

.drag-options {
    position: absolute;
    top: 44px;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 10px;
    transform: translateX(100%);
    opacity: 0;
    transition: $ease-out;

    &.active {
        transform: translateX(0);
        opacity: 1;
    }

    &-label {
        display: block;
        margin: 0 0 5px 0;

        input {
            opacity: 0.6;
        }

        span {
            display: inline-block;
            font-size: 0.9rem;
            font-weight: 400;
            margin-left: 5px;
        }
    }
}

/* Dragula CSS  */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
    list-style-type: none;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
}
