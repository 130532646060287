@layer components {
    .page-content {
        // aku komen soalnya tadi ada ngaruh waktu di kanban
        // @apply w-full p-6 mx-auto;
    }

    .multiselect {
        min-height: 42px;
        font-size: 14px;

        &.multiselect--disabled {
            background: #fff;
        }

        .multiselect__tags {
            padding: 0.5rem 0.75rem;
            min-height: 48px;

            .multiselect__tags-wrap {
                .multiselect__tag {
                }
            }
        }

        .multiselect__single {
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
}

/* auto required symbol -> use inside label class */
.required:after {
    content: " *" !important;
    color: red !important;
}

// skeleton styling

$base-color: #f4f4f4;
$shine-color: rgba(229, 229, 229, 0.8);
$animation-duration: 2s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
    background-image: linear-gradient(
        90deg,
        $base-color 0px,
        $shine-color 40px,
        $base-color 80px
    );
    background-size: 600px;
}

.tg {
    border-collapse: collapse;
    border-spacing: 0;
    border-color: #ccc;
}
.tg td {
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 10px 5px;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
    border-color: #ccc;
    color: #333;
    background-color: #fff;
}
.tg th {
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: normal;
    padding: 10px 5px;
    border-style: solid;
    border-width: 0px;
    overflow: hidden;
    word-break: normal;
    border-color: #ccc;
    color: #333;
    background-color: #f0f0f0;
}

.line, .card-loading {
    float: left;

    @include background-gradient;
    animation: shine-lines $animation-duration infinite ease-out;
}

.line {
    width: 100px;
    height: 16px;
    border-radius: 7px;
    margin-top: 12px;
}

@keyframes shine-lines {
    to {
        background-position-x: -200%;
    }
}
