@layer components {

    .label {
        @apply my-1 text-xs font-bold text-slate-700 dark:text-white/80 justify-start mr-3;
    }

    .label-text {
        @apply mx-3;
    }

    .input {
        @apply bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:shadow-primary-outline dark:bg-slate-850 dark:placeholder:text-white/80 dark:text-white/80 text-sm leading-5.6 block w-full appearance-none;
    }

    .input-bordered {
        @apply rounded-lg border border-solid border-gray-300 focus:border-blue-500 focus:outline-none;
    }

}
