.vue__time-picker {

    input.display-time {

        height: 3rem;
        padding: 0.75rem;
        font-size: 0.875rem;

    }

}
