@layer components {
    // button
    .btn-primary {
        @apply font-normal text-center text-white uppercase align-middle transition-all rounded-lg bg-blue-500 cursor-pointer leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
    .btn-secondary {
        @apply font-normal text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-slate-500 leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
    .btn-info {
        @apply font-normal text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-cyan-600 leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
    .btn-accent {
        @apply font-normal text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-emerald-500 leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
    .btn-success {
        @apply font-normal text-center text-white uppercase align-middle transition-all rounded-lg cursor-pointer bg-emerald-500 leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
    .btn-error {
        @apply font-normal text-center text-white uppercase align-middle transition-all bg-red-600 rounded-lg cursor-pointer leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
    .btn-warning {
        @apply font-normal text-center text-white uppercase align-middle transition-all bg-orange-600 rounded-lg cursor-pointer leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
    .btn-orange-red {
        @apply font-normal text-center text-white uppercase align-middle transition-all bg-gradient-to-tl from-red-500 to-orange-500 rounded-lg cursor-pointer leading-normal text-xs ease-in shadow-xs bg-150 bg-x-25 hover:-translate-y-px active:opacity-85 hover:shadow-md border-0;
    }
}
