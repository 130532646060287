
/* Dragula CSS  */

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}


.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
}

.gu-hide {
  display: none !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  box-shadow: inset 0px 0px 2px #fefefed1;
  -webkit-backdrop-filter: saturate(200%) blur(30px);
  backdrop-filter: saturate(200%) blur(30px);
  background-color: rgba(255, 255, 255, 0.8) !important;
  box-shadow: inset 0 0px 1px 1px rgba(254, 254, 254, 0.9), 0 20px 27px 0 rgba(0, 0, 0, 0.05) !important;
}

.drag_handler {
  background: #fff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: relative;
  float: left;
  top: -3px;
  margin-right: 4px;
}

.drag_handler:hover {
  cursor: move;
}

.drag_handler_icon {
  position: relative;
  display: block;
  background: #000;
  width: 24px;
  height: 2px;
  top: 12px;
  transition: .5s ease-in-out;
}

.drag_handler_icon:before,
.drag_handler_icon:after {
  background: #000;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: .5s ease-in-out;
}

.drag_handler_icon:before {
  top: 6px;
}

.drag_handler_icon:after {
  bottom: 6px;
}

.dark .kanban-board {
    background: #111c44;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  
  .dark .kanban-board .card-header {
    background: transparent;
  }
  

  .dark .kanban-board p {
    color: #fff !important;
    opacity: .6;
  }
  
  .dark .kanban-item {
    background: transparent !important;
    border: 1px solid;
  }
  
  .dark .kanban-title-board {
    color: #fff !important;
  }

  .kanban-container {
    position: relative;
    box-sizing: border-box;
    width: auto;
    /* overflow: scroll; */
  }
  
  .kanban-container * {
    box-sizing: border-box;
  }
  
  .kanban-container:after {
    clear: both;
    display: block;
    content: "";
  }
  
  .kanban-board {
    position: relative;
    float: left;
    background: #e9ecef;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
    border-radius: 0.75rem;
  }
  
  .kanban-board.disabled-board {
    opacity: 0.3;
  }
  
  .kanban-board.is-moving.gu-mirror {
    transform: rotate(3deg);
  }
  
  .kanban-board.is-moving.gu-mirror .kanban-drag {
    overflow: hidden;
    padding-right: 50px;
  }
  
  .kanban-board header {
    font-size: 16px;
    padding: 20px;
  }
  
  .kanban-board header .kanban-title-board {
    font-weight: 600;
    margin: 0;
    padding: 0;
    display: inline;
    color: #344767;
  }
  
  .kanban-board header .kanban-title-button {
    float: right;
    padding: 0.5rem 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 7px 14px rgba(50,50,93,.1),0 3px 6px rgba(0,0,0,.08);
    color: #000;
    font-size: .875rem;
    line-height: 1.5;
    font-weight: 700;
    letter-spacing: -.025rem;
  }
  
  .kanban-board .kanban-drag {
    min-height: 200px;
    padding: 20px;
  }
  
  .kanban-board:after {
    clear: both;
    display: block;
    content: "";
  }
  
  .kanban-item {
    background: #fff;
    padding: 15px;
    margin-bottom: 20px;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
  
  .kanban-item img.w-full {
    border-radius: 0.25rem;
  }
  
  .kanban-item:hover {
    cursor: move;
  }
  
  .kanban-item:last-child {
    margin: 0;
  }
  
  .kanban-item.is-moving.gu-mirror {
    transform: rotate(3deg);
    height: auto !important;
  }
  